import React, { Fragment } from 'react'

export type TableCellProps = {
  width?: string | number
  bold?: boolean
  noTdWrap?: boolean
} & JSX.IntrinsicElements['td']

export const TableCell: React.FC<TableCellProps> = ({
  width,
  bold = false,
  children,
  style,
  noTdWrap = false,
  ...props
}) => {
  if (noTdWrap) {
    return bold ? <b> {children}</b> : <Fragment>{children}</Fragment>
  }
  return (
    <td
      {...props}
      style={{
        minWidth: width ?? '',
        width: width ?? '',
        ...style
      }}
    >
      {bold ? <b> {children}</b> : children}
    </td>
  )
}

TableCell.defaultProps = {
  bold: false
}
