import React, { useState, useEffect } from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { FormGroup, FormText, Label } from 'reactstrap'

export interface TimeInputProps<T> extends UseControllerProps<T> {
  id: string
  label?: string
  errorText?: string
  disabled?: boolean
  ref?: React.LegacyRef<HTMLInputElement>
  value?: any
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  className?: string
}

export const TimeInput = <T extends FieldValues>(props: TimeInputProps<T>) => {
  let controlledProps
  let controlledFieldState
  if (props.control) {
    const { field, fieldState } = useController<T>(props)
    controlledProps = field
    controlledFieldState = fieldState
  }
  const { id, label, errorText: errorTextProp, disabled, className } = props

  const { name, ref, value, onChange } = controlledProps
    ? controlledProps
    : props
  const onBlur = props.onBlur || controlledProps?.onBlur

  const errorText = errorTextProp || controlledFieldState?.error?.message || ''

  const [isActive, setIsActive] = useState<boolean>(false)
  const [hasValue, setHasValue] = useState<boolean>(false)

  useEffect(() => {
    setHasValue(value && value !== '')
  }, [value])

  return (
    <FormGroup>
      <div
        className={`g-time-input ${hasValue ? 'has-value' : ''} ${
          isActive ? 'active' : ''
        } ${disabled ? 'disabled' : ''}`}
      >
        <input
          className={className}
          id={id}
          name={name}
          type='time'
          disabled={disabled}
          ref={ref}
          value={value && value !== '' ? value : ''}
          onFocus={() => {
            setIsActive(true)
          }}
          onChange={onChange}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e)
            }
            setIsActive(false)
          }}
        />
        {label && (
          <Label for={name} className='g-time-label'>
            {label.toUpperCase()}
          </Label>
        )}
        <div className='g-input-border' />
        {errorText && (
          <FormText className='g-input-error' color='danger'>
            {errorText}
          </FormText>
        )}
      </div>
    </FormGroup>
  )
}

TimeInput.defaultProps = {
  type: 'text',
  disabled: false,
  value: ''
}
