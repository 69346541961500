import { useEffect, useState } from 'react'

// Cuando el value cambia se fija un timer basado en el delay (en milisegundos)
// Si el delay no ha transcurrido y el valor cambia se resetea el timer.
// Si el delay se cumple y no hay nuevos cambios se actualiza el debounced value al nuevo valor
export function useDebounce<T>(
  value: T,
  delay?: number,
  disabled?: boolean
): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (!disabled) {
      timer = setTimeout(() => {
        setDebouncedValue(value)
      }, delay || 500)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay, disabled])

  return debouncedValue
}
