import React from 'react'
import { ColumnTable } from '../Table/types/TableTypes'
import { TableCollapsableRow } from './TableCollapsableRow'
import { TableCollapsableCellTd } from './types'

export type TableCollapsableBodyProps<T> = {
  columns: ColumnTable[]
  secondColumn: ColumnTable
  data: T[]
  isCollapsable?: (row: T) => boolean
  isStartCollapsed?: (row: T) => boolean
} & TableCollapsableCellTd<T>

export function TableCollapsableBody<T = any>({
  columns,
  secondColumn,
  data,
  isCollapsable = () => true,
  isStartCollapsed = () => false,
  collapsableTdProps
}: TableCollapsableBodyProps<T>) {
  return (
    <tbody>
      {data.map((res, i) => {
        const collapsable = isCollapsable(res)
        const startCollapsed = isStartCollapsed(res)
        return (
          <TableCollapsableRow
            key={`row_${i}`}
            columns={columns}
            secondColumn={secondColumn}
            data={data}
            row={res}
            index={i}
            collapsable={collapsable}
            startCollapsed={startCollapsed}
            collapsableTdProps={collapsableTdProps}
          />
        )
      })}
    </tbody>
  )
}
