import { useEffect, useRef } from 'react'

// Ejecuta el callback 1 vez luego de que pase el delay (en milisegundos)
export function useTimeout(
  callback: () => void,
  delay: number | null,
  disabled?: boolean
) {
  const savedCallback = useRef(callback)

  // Guarda el callback viejo por si cambia
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    // Si no hay delay no se programa nada
    if (delay === null || disabled === true) {
      return
    }

    const id = setTimeout(() => savedCallback.current(), delay || 0)

    return () => clearTimeout(id)
  }, [delay, disabled])
}
