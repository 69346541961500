import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Table } from 'reactstrap'
import { Icon } from '../Icon'

export type ScheduleModuleType = {
  id: number
  name: string
  startTime: string
  endTime: string
  shortening: string
}

export type ScheduleType = {
  sectionId: number
  scheduleId: number
  courseCode: string
  sectionName: string
  day: string
  activityId: number
  moduleStartId: number
  moduleEndId: number
  tooltip?: TooltipType | undefined
}

export type TooltipType = {
  courseName: string
  sectionTitle: string
  sectionName: string
  teachers: string[]
  teachersTitle: string
  classroom: string
}

interface SectionSchedule extends ScheduleType {
  intersections: number
  positionOffset: number
}

export type ScheduleProps = {
  /**
   * Secciones que se renderizarán en los días de la semana
   * el valor `day` debe coincidir con algún valor de `name` agregado en el parámetro days
   * y el valor activityId debe estar entre 1 y 8 para coincidir con los colores mantenidos
   */
  schedules: ScheduleType[]
  /**
   * Horarios disponibles en cada día
   */
  modules?: ScheduleModuleType[]
  /**
   * Días que se renderizarán en la semana
   * @type {ScheduleDay[]}
   */
  days?: ScheduleDay[]
  /**
   * Texto que se mostrará en la primera columna de los headers (Sobre los horarios de modules.)
   */
  moduleHeaderTitle: string
  /**
   * habilitará navegación para la versión mobile mostrando solo un día del horario a la vez
   * y permitiendo navegar entre días a través de flechas de dirección < >
   */
  mobileNavigation?: boolean
}

export type ScheduleDay = { id: string; headerTitle: string }

/**
 * Componente utilizado para renderizar un calendario semanal de actividades
 */
export const Schedule = ({
  schedules,
  modules = [],
  days = [
    { id: 'Lu', headerTitle: 'Lunes' },
    { id: 'Ma', headerTitle: 'Martes' },
    { id: 'Mi', headerTitle: 'Miércoles' },
    { id: 'Ju', headerTitle: 'Jueves' },
    { id: 'Vi', headerTitle: 'Viernes' },
    { id: 'Sa', headerTitle: 'Sábado' }
  ],
  moduleHeaderTitle = 'Hora',
  mobileNavigation
}: ScheduleProps) => {
  const [sections, setSections] = useState<SectionSchedule[]>([])
  const htmlBodyRef = useRef<HTMLElement | null>(null)
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null)
  const [tooltip, setTooltip] = useState<TooltipType | undefined>(undefined)
  const [tooltipId, setTooltipId] = useState<string | undefined>(undefined)
  const [tooltipStyle, setTooltipStyle] = useState<any>(undefined)
  const [currentDay, setCurrentDay] = useState<number>(1)

  const findIndexModule = (moduleId: number) => {
    return modules.findIndex((module) => module.id === moduleId)
  }

  /**
   * Ordena las secciones por la hora de inicio
   */
  const sortSections = (a: ScheduleType, b: ScheduleType) => {
    if (findIndexModule(a.moduleStartId) < findIndexModule(b.moduleStartId)) {
      return -1
    } else if (
      findIndexModule(a.moduleStartId) > findIndexModule(b.moduleStartId)
    ) {
      return 1
    } else {
      // Si comienzan en el mismo módulo, queda primero la del horario de término menor
      if (findIndexModule(a.moduleEndId) < findIndexModule(b.moduleEndId)) {
        return -1
      } else if (
        findIndexModule(a.moduleEndId) > findIndexModule(b.moduleEndId)
      ) {
        return 1
      }
      return 0
    }
  }

  const calculateSections = useCallback(() => {
    let sectionsToRender: SectionSchedule[] = []
    schedules.sort(sortSections)
    // Cálculo para saber las intersección con otros horarios
    sectionsToRender = schedules.map((schedule, i) => {
      let positionOffset = 0
      const intersections = schedules.reduce((acc, s, j) => {
        if (
          schedule.scheduleId !== s.scheduleId &&
          schedule.day === s.day &&
          ((findIndexModule(s.moduleStartId) >=
            findIndexModule(schedule.moduleStartId) &&
            findIndexModule(s.moduleStartId) <=
              findIndexModule(schedule.moduleEndId)) ||
            (findIndexModule(s.moduleEndId) >=
              findIndexModule(schedule.moduleStartId) &&
              findIndexModule(s.moduleEndId) <=
                findIndexModule(schedule.moduleEndId)))
        ) {
          // Cuantos horarios que se intersectan están antes que el horario actual
          if (j < i) {
            positionOffset = positionOffset + 1
          }
          return acc + 1
        } else {
          return acc
        }
      }, 1)

      return { ...schedule, intersections, positionOffset }
    })
    setSections(sectionsToRender)
  }, [schedules])

  useEffect(() => {
    calculateSections()
  }, [schedules])

  useEffect(() => {
    const currentDate = new Date()
    htmlBodyRef.current = document.querySelector('body') as HTMLElement
    if (
      htmlBodyRef &&
      htmlBodyRef.current &&
      htmlBodyRef.current.offsetWidth <= 768
    ) {
      if (currentDate.getDay() === 0 || currentDate.getDay() > days.length) {
        moveScrollTo(1)
      } else {
        moveScrollTo(currentDate.getDay())
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', resetTooltip, { passive: true })
    return () => {
      window.removeEventListener('scroll', resetTooltip)
    }
  }, [])

  const moveScrollTo = useCallback(
    (day: number) => {
      const tableContainer = document.querySelector('.g-schedule-scroll')
      let currentColSize = 0
      if (tableContainer) {
        scrollableContainerRef.current = tableContainer as HTMLDivElement
        if (scrollableContainerRef && scrollableContainerRef.current) {
          if (htmlBodyRef.current && htmlBodyRef.current.offsetWidth <= 576) {
            currentColSize = Math.round(
              (50 * scrollableContainerRef.current.offsetWidth) / 100
            )
          } else {
            currentColSize = Math.round(
              (70 * scrollableContainerRef.current.offsetWidth) / 100
            )
          }
          switch (day) {
            case 0:
              scrollableContainerRef.current.scrollLeft = 0
              break
            case 1:
              scrollableContainerRef.current.scrollLeft = 0
              break
            case 2:
              scrollableContainerRef.current.scrollLeft = currentColSize
              break
            case 3:
              scrollableContainerRef.current.scrollLeft = currentColSize * 2
              break
            case 4:
              scrollableContainerRef.current.scrollLeft = currentColSize * 3
              break
            case 5:
              scrollableContainerRef.current.scrollLeft = currentColSize * 4
              break
            case 6:
              scrollableContainerRef.current.scrollLeft = currentColSize * 5
              break
            case 7:
              scrollableContainerRef.current.scrollLeft = currentColSize * 6
              break
            default:
              break
          }
          setCurrentDay(day)
        }
      }
    },
    [scrollableContainerRef, setCurrentDay]
  )

  const scrollForward = useCallback(() => {
    let dayPosition = 0
    if (currentDay === 0 || currentDay + 1 > days.length) {
      dayPosition = 1
    } else {
      dayPosition = currentDay + 1
    }
    moveScrollTo(dayPosition)
  }, [currentDay, moveScrollTo])

  const scrollBackward = useCallback(() => {
    let dayPosition = 0
    if (currentDay <= 1) {
      dayPosition = currentDay
    } else {
      dayPosition = currentDay - 1
    }
    moveScrollTo(dayPosition)
  }, [currentDay, moveScrollTo])

  const showTooltip = useCallback(
    (id: string, data: TooltipType, launcher: HTMLDivElement) => {
      const position = launcher.getBoundingClientRect()
      let style = {
        display: 'block',
        top: position.top + 22 + position.height / 2,
        left: position.right - 220 + 22 - position.width / 2
      }
      if (
        tooltipId &&
        tooltipId === id &&
        tooltipStyle &&
        tooltipStyle.display === 'block'
      ) {
        style.display = 'none'
        setTooltipStyle({ ...style })
      } else {
        setTooltip({ ...data })
        setTooltipStyle({ ...style })
        setTooltipId(id)
      }
    },
    [setTooltip, setTooltipStyle, setTooltipId, tooltipId, tooltipStyle]
  )

  const resetTooltip = useCallback(() => {
    setTooltip(undefined)
    setTooltipStyle(undefined)
    setTooltipId(undefined)
  }, [setTooltip, setTooltipStyle, setTooltipId])

  const renderSchedules = (): React.ReactNode => {
    return modules?.map((module) => {
      return (
        <tr key={`module_${module.id}`}>
          <td className='module-name text-nowrap'>
            {module.shortening} {module.startTime.substr(0, 5)} -{' '}
            {module.endTime.substr(0, 5)}
          </td>
          {days.map((day) => (
            <td className='p-0' key={`day_${day.id}_${module.id}`}>
              <div className='classes-container'>
                {/* Se renderizan los horarios por día/modulo de inicio */}
                {sections
                  .filter(
                    (schedule) =>
                      schedule.day === day.id &&
                      schedule.moduleStartId === module.id
                  )
                  .map((schedule) => {
                    // Se calcula la cantidad de módulos que abarca el horario
                    const nModules =
                      schedule.moduleStartId === schedule.moduleEndId
                        ? 1
                        : findIndexModule(schedule.moduleEndId) -
                          findIndexModule(schedule.moduleStartId) +
                          1
                    const cursorClass = schedule.tooltip ? 'cursor-pointer' : ''
                    return (
                      <div
                        key={`schedule_${day}_${module.id}_${schedule.scheduleId}`}
                        className={`p-2 bg-light-schedule-${schedule.activityId} schedule-class ${cursorClass}`}
                        title={`${schedule.courseCode}-${schedule.sectionName}`}
                        style={{
                          height: `${44 * nModules - 2}px`,
                          width: `calc(100% /(${
                            schedule.intersections || 1
                          } ))`,
                          left: `calc((100% /(${schedule.intersections || 1}) *
                            ${schedule.positionOffset || 0}
                          } ))`
                        }}
                        onClick={(e) => {
                          const id = `schedule_${day}_${module.id}_${schedule.scheduleId}`
                          if (schedule.tooltip) {
                            showTooltip(id, schedule.tooltip, e.currentTarget)
                          }
                        }}
                      >
                        <span className='text-dark text-overflow-ellipsis text-uppercase fw-400'>
                          {schedule.sectionName}
                        </span>
                      </div>
                    )
                  })}
              </div>
            </td>
          ))}
        </tr>
      )
    })
  }

  return (
    <div className='g-schedule-container'>
      {mobileNavigation && (
        <div className='g-schedule-navigation'>
          <div
            className={`go-left cursor-pointer ${
              currentDay <= 1 ? 'd-none' : ''
            }`}
            onClick={scrollBackward}
          >
            <Icon name='chevron_left' size={12} />
          </div>
          <div
            className={`go-right cursor-pointer ${
              currentDay >= days.length ? 'd-none' : ''
            }`}
            onClick={scrollForward}
          >
            <Icon name='chevron_right' size={12} />
          </div>
        </div>
      )}
      {tooltip && (
        <div className='g-tooltip' style={tooltipStyle}>
          <div className='g-tooltip-icon'></div>
          <span className='g-tooltip-name'>{tooltip.courseName}</span>
          <span className='g-tooltip-section'>
            {tooltip.sectionTitle} {tooltip.sectionName}
          </span>
          <span className='g-tooltip-teacher'>
            {tooltip.teachersTitle} {tooltip.teachers.join(', ')}
          </span>
          <span className='g-tooltip-classroom'>{tooltip.classroom}</span>
        </div>
      )}
      <div className='g-schedule-scroll' onScroll={resetTooltip}>
        <Table
          className={`g-week-schedule ${
            mobileNavigation ? 'g-mobile-navigation g-days-' + days.length : ''
          }`}
          borderless
          responsive
        >
          <thead>
            <tr>
              <th>{moduleHeaderTitle}</th>
              {days.map((day) => (
                <th key={`day_${day.id}`}>{day.headerTitle}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderSchedules()}</tbody>
        </Table>
      </div>
    </div>
  )
}

Schedule.defaultProps = {
  days: [
    { id: 'Lu', headerTitle: 'Lunes' },
    { id: 'Ma', headerTitle: 'Martes' },
    { id: 'Mi', headerTitle: 'Miércoles' },
    { id: 'Ju', headerTitle: 'Jueves' },
    { id: 'Vi', headerTitle: 'Viernes' },
    { id: 'Sa', headerTitle: 'Sábado' }
  ],
  moduleHeaderTitle: 'Hora'
}
