import { useRef, useEffect } from 'react'

export function useClickedOutside(callback: () => void) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (ev: any) => {
      if (!ref.current) return

      if (!ref.current?.contains(ev.target) && callback) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [callback])

  return { ref }
}
