import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { useMobile } from '../../hooks'
import { Icon, IconPaths } from '../Icon'

export type SearchAccordionProps = {
  /**
   * Texto que tendrá el botón que muestra y oculta el contenido
   */
  btnText: string
  /**
   * Icono que tendrá el botón que muestra y oculta el contenido
   */
  iconName?: keyof typeof IconPaths
  /**
   * Contenido que se mostrara
   */
  children?: React.ReactNode
}

/**
 * Componente utilizado para mostrar y ocultar los filtros de búsqueda en versión móvil
 * NOTA: Para ver el componente debes entrar en canvas y seleccionar el tamaño "small mobile" o "large mobile"
 */
export const SearchAccordion: React.FunctionComponent<SearchAccordionProps> = ({
  children,
  btnText,
  iconName = 'filter'
}) => {
  const isMobile = useMobile()
  const [showFilters, setShowFilters] = useState(false)
  const classPanel = showFilters ? 'collapse show' : 'collapse'

  return (
    <React.Fragment>
      <Row className='g-search-accordion'>
        {isMobile && (
          <Col xs={12}>
            <div
              role='button'
              onClick={() => setShowFilters((prevState) => !prevState)}
              className='g-button-accordion'
              data-toggle='collapse'
            >
              <Icon color='secondary' size={14} name={iconName} />
              <span>{btnText}</span>
              <Icon
                className='g-button-chevron'
                color='secondary'
                size={11}
                name={showFilters ? 'chevron_up' : 'chevron_down'}
              />
            </div>
          </Col>
        )}

        <Col
          xs={12}
          className={`g-accordion-panel ${isMobile ? classPanel : ''}`}
        >
          {children}
        </Col>
      </Row>
    </React.Fragment>
  )
}
