import React, { useState } from 'react'
import clsx from 'clsx'
import {
  SidenavLinkType,
  SidenavCategoryType,
  DefaultSidenavLinkType
} from './types'
import { SidenavItem } from './SidenavItem'
import { SidenavCategory } from './SidenavCategory'

// Determina si algun enlace hijo de una categoria esta activo ahora (hace match con la URL del browser)
const hasChildrenWithRouteMatch = (childLinks: SidenavLinkType[]) => {
  return childLinks.some((link) =>
    window.location.pathname.startsWith(link.path)
  )
}

export interface SidenavMenuProps<
  LinkComponent extends React.ComponentType<any> = DefaultSidenavLinkType
> {
  collapsed: boolean
  toggleCollapsed: () => void
  links: (SidenavLinkType | SidenavCategoryType)[]
  afterLinkSelection?: (selectedLink: SidenavLinkType) => void
  linkComponent: LinkComponent
  mapLinkObjectToLinkComponentProps: (
    link: SidenavLinkType
  ) => React.ComponentProps<LinkComponent>
}

/**
 * Este componente sostiene los itemns del menu y la logica necesaria para su interaccion.
 * Segun los enlaces recibidos en el sidenav mostrara una categoria o un enlace y manejara la forma en la que se pintan
 */
export function SidenavMenu<LinkComponent extends React.ComponentType<any>>({
  collapsed,
  toggleCollapsed,
  links,
  afterLinkSelection = () => null,
  linkComponent,
  mapLinkObjectToLinkComponentProps
}: SidenavMenuProps<LinkComponent>) {
  const [openedCategoryIdx, setOpenedCategoryIdx] = useState<number>(() => {
    const matchedCategoryIdx = links.findIndex((link) => {
      // Le decimos al compilador de ts que lo evalue como categoria
      const childLinks = (link as SidenavCategoryType).links

      // Si es una categoria childLinks tendra valor, de lo contrario sera undefined
      return hasChildrenWithRouteMatch(childLinks || [])
    })

    // De no haber conseguido una categoria que haga match, el valor del idx sera -1
    return matchedCategoryIdx
  })

  const handleCategoryToggle = (newIndex: number) => {
    // Funcion de toggle si la categoria ya estaba abierta
    setOpenedCategoryIdx((currIndex) => {
      if (currIndex === newIndex && !collapsed) return -1
      return newIndex
    })

    if (collapsed) {
      toggleCollapsed()
    }
  }

  return (
    <div
      className={clsx(
        `flex-grow-1 overflow-auto sidenav-menu-container`,
        collapsed && 'overflow-scroll-hidden'
      )}
    >
      <div className='d-flex h-100 flex-column' style={{ gap: 10 }}>
        {links.map((current, index) => {
          const { name, icon } = current

          // Es una categoria si
          if ((current as SidenavCategoryType).links) {
            const { links: childLinks } = current as SidenavCategoryType
            const isActive = hasChildrenWithRouteMatch(childLinks || [])

            return (
              <SidenavCategory
                key={index}
                icon={icon!}
                label={name}
                links={childLinks}
                collapsed={collapsed}
                isActive={isActive}
                isOpened={openedCategoryIdx === index}
                onSelect={() => handleCategoryToggle(index)}
                linkComponent={linkComponent}
                mapLinkObjectToLinkComponentProps={
                  mapLinkObjectToLinkComponentProps
                }
                afterLinkSelection={afterLinkSelection}
              />
            )
          }

          // Si no, solo pintamos el enlace
          const mappedProps = {
            ...mapLinkObjectToLinkComponentProps(current as SidenavLinkType),
            onClick: () => {
              afterLinkSelection(current as SidenavLinkType)
            }
          }

          return (
            <SidenavItem
              key={index}
              icon={icon}
              label={name}
              collapsed={collapsed}
              onClick={() => setOpenedCategoryIdx(-1)}
              linkComponent={linkComponent}
              linkComponentProps={mappedProps}
            />
          )
        })}
      </div>
    </div>
  )
}
