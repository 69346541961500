import clsx from 'clsx'
import React, { CSSProperties } from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { FormGroup, FormText, Label, UncontrolledTooltip } from 'reactstrap'

import { Formatters, formatters } from '../../../utils/formatters'
import { Icon } from '../../Icon'

export interface TextOutlinedInputProps<T> extends UseControllerProps<T> {
  type?: 'text' | 'password'
  label?: string
  tooltip?: string | JSX.Element
  errorText?: string
  disabled?: boolean
  autocomplete?: string
  placeholder?: string
  ref?: React.LegacyRef<HTMLInputElement>
  value?: any
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  formatter?: keyof typeof Formatters | ((value: any) => string)
  minLength?: number
  maxLength?: number
  textAlign?: CSSProperties['textAlign']
  readOnly?: boolean
}

export const TextOutlinedInput = <T extends FieldValues>(
  props: TextOutlinedInputProps<T>
) => {
  let controlledProps
  let controlledFieldState
  if (props.control) {
    const { field, fieldState } = useController<T>(props)
    controlledProps = field
    controlledFieldState = fieldState
  }
  const {
    type,
    label,
    tooltip,
    errorText: errorTextProp,
    disabled,
    autocomplete,
    placeholder,
    formatter,
    minLength,
    maxLength,
    textAlign,
    readOnly
  } = props

  const { name, ref, value, onChange } = controlledProps || props

  const onBlur = props.onBlur || controlledProps?.onBlur

  const format =
    formatter && typeof formatter === 'string'
      ? formatters[formatter]
      : formatter

  const errorText = errorTextProp || controlledFieldState?.error?.message || ''

  return (
    <FormGroup>
      {label && (
        <Label for={name} className='g-outlined-input-label'>
          {label.toUpperCase()}
        </Label>
      )}
      <div
        className={clsx(
          'g-text-outlined-input-container',
          disabled && 'disabled',
          readOnly && 'read-only'
        )}
      >
        <input
          className='g-text-outlined-input'
          id={name}
          type={type}
          name={name}
          disabled={disabled}
          autoComplete={autocomplete}
          placeholder={placeholder}
          ref={ref}
          value={value && value !== '' && format ? format(value) : value || ''}
          onChange={onChange}
          onBlur={onBlur}
          style={{ paddingRight: tooltip ? '40px' : 0, textAlign }}
          minLength={minLength}
          maxLength={maxLength}
          readOnly={readOnly}
        />

        {tooltip && (
          <div className='g-outlined-input-tooltip' id={`inputTooltip_${name}`}>
            <Icon name='information_input' size='1rem' />
            <UncontrolledTooltip
              placement='bottom'
              target={`inputTooltip_${name}`}
              fade={false}
            >
              {tooltip}
            </UncontrolledTooltip>
          </div>
        )}
      </div>

      {errorText && (
        <FormText className='g-input-error' color='danger'>
          {errorText}
        </FormText>
      )}
    </FormGroup>
  )
}

TextOutlinedInput.defaultProps = {
  type: 'text',
  disabled: false,
  value: ''
}
