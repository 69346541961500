import React from 'react'
import { ColumnTable } from './types/TableTypes'

type TableHeadProps = {
  columns: ColumnTable[]
  subHeader?: JSX.Element
}

export const TableHead = ({ columns, subHeader }: TableHeadProps) => {
  return (
    <thead>
      <tr>
        {columns.map((col, i) => {
          const thProps =
            typeof col.thProps === 'function'
              ? col.thProps({ column: col, index: i })
              : col.thProps
          return (
            <th
              className={col.thClassName || ''}
              key={`header_${i}`}
              style={{
                minWidth: col.width ? col.width : '',
                width: col.width ? col.width : ''
              }}
              {...thProps}
            >
              {col.headerText}
            </th>
          )
        })}
      </tr>
      {columns.length > 0 && subHeader && (
        <tr className='sub-header'>
          <th colSpan={columns.length}>{subHeader} </th>
        </tr>
      )}
    </thead>
  )
}
