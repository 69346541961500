import React, { useCallback, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { Logo } from '../Logo'
import { SidenavLogoutButton } from './SidenavLogoutButton'
import { useSidenav } from './SidenavProvider'
import { useSidenavState } from './useSidenavState'
import { HamburgerButton } from './HamburgerButton'
import { SidenavMenu } from './SidenavMenu'
import {
  DefaultSidenavLinkType,
  LogoWithFallback,
  SidenavCategoryType,
  SidenavLinkType
} from './types'
import {
  DefaultLinkComponent,
  DefaultMapLinkToProps,
  DEFAULT_LOGO
} from './constants'
import { useMobile } from '../../hooks'

const classes = {
  disableScroll: 'disable-scroll',
  backdrop: 'g-sidenav-backdrop',
  root: 'g-sidenav-root',
  sidenavDesktop: 'g-sidenav-desktop',
  sidenavMobile: 'g-sidenav-mobile',
  open: 'open',
  closed: 'closed',
  logo: 'logo',
  logoHidden: 'logoHidden'
}

export interface SidenavProps<
  LinkComponent extends React.ComponentType<any> = DefaultSidenavLinkType
> {
  links: (SidenavLinkType | SidenavCategoryType)[]
  logo?: {
    desktop: LogoWithFallback
    mobile: LogoWithFallback
  }
  onLogout?: () => void
  linkComponent?: LinkComponent
  mapLinkObjectToLinkComponentProps?: (
    link: SidenavLinkType
  ) => React.ComponentProps<LinkComponent>
}

export function Sidenav<LinkComponentType extends React.ComponentType<any>>({
  logo = DEFAULT_LOGO,
  links,
  onLogout,
  linkComponent,
  mapLinkObjectToLinkComponentProps
}: SidenavProps<LinkComponentType>) {
  const isMobile = useMobile()
  const LinkComponent = linkComponent || DefaultLinkComponent

  const providerState = useSidenav()
  const internalState = useSidenavState()

  const handleToggle = useCallback(() => {
    if (providerState.foundProvider) providerState.toggle()
    else internalState.toggle()
  }, [providerState, internalState])

  const state = useMemo(() => {
    return providerState.foundProvider
      ? providerState.state
      : internalState.state
  }, [providerState, internalState])

  const hideExpandedLogo = !(state === 'open' || isMobile)

  useEffect(() => {
    if (providerState.foundProvider) providerState.reset()
    else internalState.reset()
  }, [isMobile, providerState.reset, internalState.reset])

  useEffect(() => {
    if (isMobile && state === 'open') {
      document.body.classList.add(classes.disableScroll)
    } else {
      document.body.classList.remove(classes.disableScroll)
    }
  }, [isMobile, state])

  return (
    <React.Fragment>
      {isMobile && state === 'open' && (
        <div className={classes.backdrop} onClick={handleToggle} />
      )}
      <div
        className={clsx(classes.root, {
          [classes.sidenavDesktop]: !isMobile,
          [classes.sidenavMobile]: isMobile,
          [classes.open]: state === 'open',
          [classes.closed]: state === 'closed'
        })}
      >
        {/* BOTÓN DE HAMBURGUESA QUE ABRE Y CIERRA EL MENÚ */}
        <div
          className={clsx('p-3 pt-4', {
            'text-right': state === 'open' || isMobile,
            'text-center': !(state === 'open' || isMobile)
          })}
        >
          <HamburgerButton onClick={handleToggle} />
        </div>

        {/* LOGO DE GLOBAL 3000 ANALYTICS */}
        <div className='p-3' style={{ height: '9rem', whiteSpace: 'nowrap' }}>
          <Logo
            className={clsx(classes.logo, {
              [classes.logoHidden]: hideExpandedLogo
            })}
            alt='logo'
            src={logo.desktop.src}
            fallbackSrc={logo.desktop.fallbackSrc}
          />

          <Logo
            className={clsx(classes.logo, {
              [classes.logoHidden]: !hideExpandedLogo
            })}
            alt='logo'
            src={logo.mobile.src}
            fallbackSrc={logo.mobile.fallbackSrc}
          />
        </div>

        {/* Categorias y Links de la navegación  */}
        <SidenavMenu
          links={links}
          linkComponent={LinkComponent}
          mapLinkObjectToLinkComponentProps={
            mapLinkObjectToLinkComponentProps || DefaultMapLinkToProps
          }
          collapsed={state !== 'open' && !isMobile}
          toggleCollapsed={handleToggle}
          afterLinkSelection={() => {
            if (state === 'open' && !isMobile) {
              handleToggle()
            }
          }}
        />

        {/* BOTÓN PARA CERRAR SESIÓN */}
        {onLogout && (
          <SidenavLogoutButton
            collapsed={state !== 'open' && !isMobile}
            onClick={onLogout}
          />
        )}
      </div>
    </React.Fragment>
  )
}
