import React from 'react'
import clsx from 'clsx'
import { Icon, IconProps } from '../Icon'
import { DefaultSidenavLinkType, SidenavLinkType } from './types'
import { DefaultLinkComponent } from './constants'

const classes = {
  root: 'g-sidenav-category-root',
  label: 'g-sidenav-category-label',
  button: 'g-sidenav-category-btn',
  itemsContainer: 'g-sidenav-items-container',
  link: 'g-sidenav-item-link',
  border: 'g-sidenav-item-border',
  collapsed: 'collapsed',
  active: 'active',
  opened: 'opened'
}

export interface SidenavCategoryProps<
  LinkComponent extends React.ComponentType<any> = DefaultSidenavLinkType
> {
  isActive: boolean
  isOpened: boolean
  onSelect: () => void
  label: string
  icon: IconProps['name']
  links: SidenavLinkType[]
  linkComponent?: LinkComponent
  mapLinkObjectToLinkComponentProps?: (
    link: SidenavLinkType
  ) => LinkComponent | {}
  collapsed?: boolean
  afterLinkSelection?: (selectedLink: SidenavLinkType) => void
}

export function SidenavCategory<
  LinkComponent extends React.ComponentType<any>
>({
  isActive = false,
  isOpened = false,
  onSelect = () => null,
  label,
  icon,
  links,
  collapsed,
  linkComponent,
  mapLinkObjectToLinkComponentProps,
  afterLinkSelection = () => null
}: SidenavCategoryProps<LinkComponent>) {
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: isActive,
        [classes.opened]: isOpened && !collapsed
      })}
    >
      <button
        className={clsx(
          classes.button,
          collapsed && 'd-flex justify-content-center'
        )}
        onClick={() => onSelect()}
      >
        {icon && (
          <Icon
            className='flex-grow-0 flex-shrink-0 '
            name={icon}
            size='20px'
          />
        )}
        <span
          className={clsx(
            'pl-3 flex-grow-1',
            classes.label,
            collapsed && 'd-none'
          )}
        >
          {label}
        </span>
        <Icon
          size={11}
          name={isOpened ? 'chevron_up' : 'chevron_down'}
          className={clsx(isOpened && 'mt-n1', collapsed && 'd-none')}
        />
      </button>
      <span className={clsx('border-primary', classes.border)} />

      <ul className={clsx(classes.itemsContainer, 'mb-0')}>
        {links.map((link, idx) => {
          const LinkComponent = linkComponent || DefaultLinkComponent
          const mappedProps = mapLinkObjectToLinkComponentProps
            ? mapLinkObjectToLinkComponentProps(link)
            : { className: '' }
          const mappedWithAfterSelection = {
            ...mappedProps,
            onClick: () => afterLinkSelection(link)
          }

          return (
            <li key={idx} className='d-block pl-3'>
              <LinkComponent
                {...mappedWithAfterSelection}
                className={clsx(classes.link, (mappedProps as any)?.className)}
              >
                <span className='d-block pl-2 text-truncate'>{link.name}</span>
              </LinkComponent>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
