import React from 'react'
import { Col, Row } from 'reactstrap'

export type ScheduleLegendsProps = {
  /**
   * Array con las leyendas a renderizar ({id: number, name: string})
   * Los ids deben estar entre 1 y 8 (para calzar con los colores mantenidos)
   */
  legends: ScheduleLegendType[]
}

export type ScheduleLegendType = { id: number; name: string }

/**
 * Componente utilizado para renderizar las leyendas asociadas a los horarios
 * desplegados en el componente Schedule
 */
export const ScheduleLegends = ({ legends }: ScheduleLegendsProps) => {
  return (
    <Row className='g-legends-container'>
      {legends.map((legend) => (
        <Col xs={6} sm={4} md={3} className='pb-4' key={`legend_${legend.id}`}>
          <span className={`legend bg-schedule-${legend.id}`}></span>
          {legend.name}
        </Col>
      ))}
    </Row>
  )
}
