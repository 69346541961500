import React, { useReducer } from 'react'
import clsx from 'clsx'
import { Icon, IconProps } from '../Icon'
import { useClickedOutside } from '../../hooks'

interface MenuItem {
  icon?: IconProps['name']
  text: string
  onClick: () => void
}

export interface UserMenuProps {
  fullName: string
  email: string
  menuItems: MenuItem[]
}

export function UserMenu({ fullName, email, menuItems }: UserMenuProps) {
  const [active, toggleActive] = useReducer((prevState) => !prevState, false)
  const { ref: userMenuRef } = useClickedOutside(() => {
    if (active) toggleActive()
  })

  const handleMenuItemClick = (onClickFn: MenuItem['onClick']) => {
    onClickFn()
    toggleActive()
  }

  return (
    <React.Fragment>
      <div
        className={clsx(
          'modal fade modal-backdrop zIndex-popover w-100',
          active && 'show d-block'
        )}
      />
      <div
        className={clsx('g-user-menu', active && 'active')}
        ref={userMenuRef}
      >
        <button className='actionable' onClick={toggleActive}>
          <div className='avatar'>
            <Icon name='user' size={26} color='white' />
          </div>
          <span className='fullName'>{fullName}</span>
        </button>
        <div className='toggleable-menu-container'>
          <div className='toggleable-menu'>
            <div className='user-data'>
              <div className='avatar'>
                <Icon name='user' size={39} color='white' />
              </div>
              <div className='data-container'>
                <p className='mb-0 text-dark fw-600' title={fullName}>
                  {fullName}
                </p>
                <p className='mb-0 text-dark' title={email}>
                  {email}
                </p>
              </div>
            </div>
            <div className='separator'>
              <hr />
            </div>
            <ul className='links-list'>
              {menuItems.map(({ icon, text, onClick }, i) => (
                <li
                  tabIndex={0}
                  onClick={() => handleMenuItemClick(onClick)}
                  key={`${text}-${i}`}
                >
                  {icon && <Icon name={icon} size={12} />}
                  <span>{text}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
