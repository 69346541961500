import React, { ReactNode } from 'react'
import { Sidenav, SidenavProps } from './Sidenav'
import { SidenavProvider } from './SidenavProvider'

// El logo puede ser sobre escrito si se especifica en las props, en caso contrario el sidenav
export interface SidenavLayoutProps<
  LinkComponent extends React.ComponentType<any>
> extends SidenavProps<LinkComponent> {
  children: ReactNode
}

/**
 * Componente utilizado como layout que utiliza un Sidenav.
 * De no cumplir con lo requerido puede utilizar el código como base.
 *
 * `MemoryRouter` es para que no lance error el `NavLink`, pues se requiere un `Router`.
 */
export function SidenavLayout<LinkComponent extends React.ComponentType<any>>(
  props: SidenavLayoutProps<LinkComponent>
) {
  const { children, ...sidenavProps } = props

  return (
    <SidenavProvider>
      <div className='d-flex min-vh-100'>
        <Sidenav {...sidenavProps} />
        <div className='flex-grow-1' style={{ minWidth: 0 }}>
          {children}
        </div>
      </div>
    </SidenavProvider>
  )
}
