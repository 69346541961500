import React from 'react'
import { NavLink } from 'react-router-dom'
import { SidenavLinkType } from './types'

export const DEFAULT_LOGO = {
  desktop: {
    src: '/tenant/logo_expanded.svg',
    fallbackSrc: '/tenant/logo_expanded.png'
  },
  mobile: {
    src: '/tenant/logo_small.svg',
    fallbackSrc: '/tenant/logo_small.png'
  }
}

export const DefaultLinkComponent = (props: any) => <NavLink {...props} />
export const DefaultMapLinkToProps = (link: SidenavLinkType) => {
  return {
    title: link.name,
    to: link.path
  }
}
