import React from 'react'
import { Icon } from '../Icon'

const classes = {
  menuIcon: 'menuIcon'
}

interface SidenavHamburgerButtonProps {
  onClick?: () => void
}

export function HamburgerButton({
  onClick = () => null
}: SidenavHamburgerButtonProps) {
  return (
    <button
      className='bg-transparent border-0 cursor-pointer icon-bars'
      onClick={onClick}
    >
      <Icon name='menu' size='22px' className={classes.menuIcon} />
    </button>
  )
}
