import React from 'react'
import clsx from 'clsx'

export interface TextFieldProps {
  placeholder: string
  children: string
  border?: boolean
  style?: Object
}

/**
 * Componente utilizado para desplegar información relevante en el header de las páginas
 */
export const TextField = ({
  placeholder,
  children,
  border = true,
  style = {}
}: TextFieldProps) => {
  return (
    <div
      className={clsx(
        'g-text-field d-flex flex-column text-primary mb-4 mb-md-0 overflow-auto',
        !border && 'hideBorder'
      )}
      style={style}
    >
      <div className='title text-overflow-ellipsis'>
        <span>{placeholder}</span>
      </div>
      <div className='text-overflow-ellipsis'>
        <span title={children}>{children}</span>
      </div>
    </div>
  )
}
