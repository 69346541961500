import React from 'react'
import clsx from 'clsx'
import { Button } from 'reactstrap'
import { Icon } from '../Icon'

const classes = {
  button: 'g-sidenav-logout-button',
  label: 'g-sidenav-logout-label',
  collapsed: 'collapsed'
}

export interface SidenavLogoutButtonProps {
  collapsed?: boolean
  onClick: () => void
}

export const SidenavLogoutButton = (props: SidenavLogoutButtonProps) => {
  const { collapsed, onClick } = props
  return (
    <Button
      cssModule={{ btn: classes.button }}
      className='text-primary'
      outline
      onClick={onClick}
    >
      <Icon name='log_out' size='13' className='flex-shrink-0' />
      <span className={clsx(classes.label, classes.collapsed && collapsed)}>
        Cerrar Sesión
      </span>
    </Button>
  )
}
