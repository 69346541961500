import React, { useState } from 'react'
import { TableCell } from '../Table/TableCell'
import { ColumnTable } from '../Table/types/TableTypes'
import { Icon } from '../Icon'
import { TableCollapsableCellTd } from './types'

export type TableCollapsableBodyProps<T> = {
  columns: ColumnTable[]
  secondColumn: ColumnTable
  data: T[]
  row: T
  index: number
  collapsable?: boolean
  startCollapsed?: boolean
} & TableCollapsableCellTd<T>

export function TableCollapsableRow<T = any>({
  columns,
  secondColumn,
  data,
  row,
  index,
  collapsable = true,
  startCollapsed = false,
  collapsableTdProps
}: TableCollapsableBodyProps<T>) {
  const [expanded, setExpanded] = useState<boolean>(startCollapsed)

  return (
    <React.Fragment>
      <tr>
        {columns.map((col, j) => {
          const options = {
            row: row,
            value: row[col.columnName],
            index,
            data,
            column: col
          }

          const className =
            typeof col.tdClassName === 'function'
              ? col.tdClassName(options)
              : col.tdClassName

          const tdProps =
            typeof col.tdProps === 'function'
              ? col.tdProps(options)
              : col.tdProps
          return (
            <TableCell
              className={className}
              key={`body_${index}_${j}`}
              data-key={`body_${index}_${j}`}
              bold={col.bold}
              width={col.width}
              {...tdProps}
            >
              {col.cellFormat ? col.cellFormat(options) : row[col.columnName]}
            </TableCell>
          )
        })}
        <td key='collapse' {...collapsableTdProps}>
          {collapsable && (
            <span onClick={() => setExpanded((e) => !e)}>
              <Icon
                className='cursor-pointer'
                name={expanded ? 'chevron_up' : 'chevron_down'}
                size='18px'
              />
            </span>
          )}
        </td>
      </tr>
      {collapsable && expanded && (
        <React.Fragment>
          <tr key={`row_phantom_${index}`} data-key={`row_phantom_${index}`} />
          <tr key={`row_second_${index}`} data-key={`row_second_${index}`}>
            {[secondColumn].map((col, j) => {
              const options = {
                row: row,
                value: row[col.columnName],
                index: index,
                data,
                column: col
              }

              const className =
                typeof col.tdClassName === 'function'
                  ? col.tdClassName(options)
                  : col.tdClassName

              return (
                <TableCell
                  className={className}
                  key={`body_second_${index}_${j}`}
                  data-key={`body_second_${index}_${j}`}
                  bold={col.bold}
                  width={col.width}
                  colSpan={columns.length + 1}
                >
                  {col.cellFormat
                    ? col.cellFormat(options)
                    : row[col.columnName]}
                </TableCell>
              )
            })}
          </tr>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
