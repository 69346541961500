import { useState, useCallback } from 'react'

export type SidenavState = 'initial' | 'open' | 'closed'

type StateTransitions = {
  [index in SidenavState]: SidenavState
}

const STATE_TRANSITIONS: StateTransitions = {
  initial: 'open',
  open: 'closed',
  closed: 'open'
}

export function useSidenavState() {
  const [state, setState] = useState<SidenavState>('initial')

  const toggle = useCallback(() => {
    setState((prev) => STATE_TRANSITIONS[prev])
  }, [])

  const reset = useCallback(() => {
    setState('initial')
  }, [])

  return {
    state,
    toggle,
    reset
  }
}
