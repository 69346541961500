import React from 'react'
import { Col, Row } from 'reactstrap'
import { ColumnProps } from 'reactstrap/es/Col'

export type DetailBoxColumn = {
  title: string
  body?: string
  size?: {
    xs?: ColumnProps
    sm?: ColumnProps
    md?: ColumnProps
    lg?: ColumnProps
    xl?: ColumnProps
  }
}

export interface DetailBoxProps {
  columns: DetailBoxColumn[]
}

export const DetailBox = ({ columns }: DetailBoxProps) => {
  return (
    <div className='g-detail-box'>
      <Row className='m-0'>
        {columns.map(({ title, body = '', size = {} }, i) => {
          return (
            <Col {...size} key={`detailBox_${i}_${title}`} className='column'>
              <div className='text-overflow-ellipsis'>
                <span className='text-primary fw-700 text-uppercase fs-14'>
                  {title}
                </span>
              </div>
              <div className='text-overflow-ellipsis'>
                <span title={body} className='fs-14 text-primary '>
                  {body}
                </span>
              </div>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}
