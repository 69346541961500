import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'

type Heights = {
  minHeight?: string | number
  maxHeight?: string | number
  height?: string | number
}

const classes = {
  root: 'g-logo-root'
}

export interface LogoProps {
  src: string
  fallbackSrc: string
  style?: Heights
  alt?: string
  className?: string
}

export const Logo = ({
  src: srcProp,
  fallbackSrc,
  alt = 'logo',
  style = {},
  className
}: LogoProps) => {
  const [src, setSrc] = useState(srcProp)

  useEffect(() => {
    setSrc(srcProp)
  }, [srcProp])

  const onError = useCallback(() => {
    setSrc(fallbackSrc)
  }, [fallbackSrc])

  return (
    <img
      className={clsx(classes.root, className)}
      src={src}
      alt={alt}
      onError={onError}
      style={style}
    />
  )
}
