import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { FormGroup, FormText } from 'reactstrap'
import { FocusEventHandler, MenuPosition } from 'react-select'
import AsyncSelect from 'react-select/async'
import { SelectOptionType } from './Select'
import debounce from 'lodash/debounce'
import Control from './Control'
import DropdownIndicator from './DropdownIndicator'
import ClearIndicator from './ClearIndicator'

export interface RemoteSelectProps<T> extends UseControllerProps<T> {
  label: string
  disabled?: boolean
  value?: SelectOptionType
  /**
   * Función que obtendrá el listado de opciones en base a la búsqueda realizada por el usuario
   */
  filterOptions: (searchText: string) => Promise<SelectOptionType[]>
  /**
   * Deja las opciones buscadas en cache para evitar realizar nuevamente las mismas búsquedas.
   * Si en tu interfaz es posible agregar más valores que deberían aparecer en el select NO se debería activar esta opción
   */
  cacheOptions?: boolean
  onChange?: ((value: any, actionMeta: any) => void) &
    ((value: any, action: any) => void)
  onBlur?: FocusEventHandler
  placeholder?: string
  /**
   * Texto que se mostrará cuando haya un error en el campo Ej: "Debe seleccionar una opción"
   */
  errorText?: string
  /**
   * Utilizado para indicar si el selector es múltiple
   */
  isMulti?: boolean
  /**
   * Utilizado para indicar si se mostrará el icono "X" para remover la opción seleccionada
   */
  isClearable?: boolean
  /**
   * Utilizado para indicar si se podrá escribir texto para buscar entre las opciones disponibles
   */
  isSearchable?: boolean
  /**
   * Mensaje mostrado mientras carga las opciones del select al buscar
   */
  loadingMessage: string
  /**
   * Mensaje que se mostrará para que el usuario comience la búsqueda
   * Ej: "Ingrese un nombre para buscar profesores"
   */
  placeholderSearchText: string
  /**
   * Mensaje que aparecerá cuando se abre el select y no hay opciones disponibles
   * ó cuando no se encuentran resultados al filtrar las opciones con texto de búsqueda
   */
  noOptionsMessage: string
  menuPosition?: MenuPosition
  /**
   * Cuando menuPosition es "fixed" esta propiedad debería ser true
   * por problema para actualizar la posición al hacer scroll
   * https://github.com/JedWatson/react-select/issues/3734
   */
  menuShouldBlockScroll?: boolean
}

/**
 * Select utilizado en casos donde existen muchos registros (opciones)
 * por lo que se utiliza el prop `filterOptions` para entregarle un función
 * que retorne el listado de opciones en base a un texto
 * entregado por la búsqueda del usuario
 * @param props
 * @returns
 */
export const RemoteSelect = <T extends FieldValues>(
  props: RemoteSelectProps<T>
) => {
  let controlledProps
  let controlledFieldState
  if (props.control) {
    const { field, fieldState } = useController<T>(props)
    controlledProps = field
    controlledFieldState = fieldState
  }
  const {
    filterOptions,
    cacheOptions = false,
    label,
    disabled,
    errorText,
    placeholder,
    isMulti,
    isClearable,
    isSearchable,
    loadingMessage,
    placeholderSearchText,
    noOptionsMessage,

    menuPosition = 'absolute',
    menuShouldBlockScroll = false
  } = props

  const { name, value, onChange, onBlur } = controlledProps || props
  const ref = controlledProps?.ref

  const loadOptions = React.useCallback(
    debounce((inputValue, callback) => {
      filterOptions(inputValue)
        .then((options) => callback(options))
        .catch(() => callback([]))
    }, 1000),
    []
  )

  return (
    <FormGroup>
      <div className='g-select-container'>
        <AsyncSelect
          id={name}
          name={name}
          label={label}
          className='g-select-base'
          classNamePrefix='g-select'
          components={{
            Control,
            DropdownIndicator,
            ClearIndicator
          }}
          loadOptions={loadOptions}
          loadingMessage={() => loadingMessage}
          cacheOptions={cacheOptions}
          isDisabled={disabled}
          isMulti={isMulti}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          blurInputOnSelect
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable={isSearchable}
          noOptionsMessage={({ inputValue }: { inputValue: string }) => {
            return inputValue.length > 0
              ? noOptionsMessage
              : placeholderSearchText
          }}
          menuPosition={menuPosition}
          menuShouldBlockScroll={menuShouldBlockScroll}
        />

        <FormText className='g-input-error' color='danger'>
          {errorText || controlledFieldState?.error?.message || ''}
        </FormText>
      </div>
    </FormGroup>
  )
}

RemoteSelect.defaultProps = {
  disabled: false,
  cacheOptions: false,
  isClearable: true,
  isSearchable: true,
  menuPosition: 'absolute',
  menuShouldBlockScroll: false,
  isMulti: false
}
