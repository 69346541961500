import clsx from 'clsx'
import React from 'react'
import { Icon, IconProps } from '../Icon'
import { DefaultLinkComponent } from './constants'
import { DefaultSidenavLinkType } from './types'

const classes = {
  root: 'g-sidenav-item-root',
  link: 'g-sidenav-item-link',
  label: 'g-sidenav-item-label',
  collapsed: 'collapsed',
  border: 'g-sidenav-item-border'
}

type LinkComponent = React.ComponentType<any>

export interface SidenavItemProps<
  L extends LinkComponent = DefaultSidenavLinkType
> {
  label: string
  icon?: IconProps['name']
  active?: boolean
  linkComponent?: LinkComponent
  linkComponentProps?: React.ComponentProps<L>
  collapsed?: boolean
  onClick?: () => void
}

export function SidenavItem<L extends LinkComponent>({
  collapsed = false,
  label,
  icon,
  linkComponent,
  linkComponentProps,
  onClick
}: SidenavItemProps<L>) {
  const LinkComponent = linkComponent || DefaultLinkComponent

  return (
    <div className={clsx(classes.root)} onClick={onClick}>
      <LinkComponent
        {...linkComponentProps}
        className={clsx(
          classes.link,
          linkComponentProps?.className,
          collapsed && 'd-flex justify-content-center'
        )}
      >
        {icon && (
          <Icon className='flex-grow-0 flex-shrink-0' name={icon} size='20px' />
        )}
        <span
          className={clsx('pl-3 flex-grow-1', classes.label, {
            [classes.collapsed]: collapsed
          })}
        >
          {label}
        </span>
      </LinkComponent>
      <span className={clsx('border-primary', classes.border)} />
    </div>
  )
}
