import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { FormGroup, FormText, Label } from 'reactstrap'
import { Icon } from '../../Icon'

export interface SearchBoxProps<T> extends UseControllerProps<T> {
  label?: string
  /**
   * Valor del texto de búsqueda
   */
  value?: string
  ref?: React.LegacyRef<HTMLInputElement>
  /**
   * Indica si el input está habilitado
   */
  disabled?: boolean
  /**
   * Texto que se desplegará cuando aún no exista valor en el campo
   */
  placeholder?: string
  /**
   * Evento que se lanza cuando se modifica el texto en el componente
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  /**
   * Evento que se lanza al salir del foco del componente
   */
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  autocomplete?: string
  errorText?: string
  minLength?: number
  maxLength?: number
  /**
   * Evento que permite limpiar el valor de input
   * Se puede modificar el valor de value usando useState
   * Si se está utilizando react-hook-forms se puede utilizar setState
   */
  clear?: () => void
}

export const SearchBox = <T extends FieldValues>(props: SearchBoxProps<T>) => {
  let controlledProps
  let controlledFieldState
  if (props.control) {
    const { field, fieldState } = useController<T>(props)
    controlledProps = field
    controlledFieldState = fieldState
  }
  const {
    label,
    errorText,
    disabled,
    autocomplete,
    placeholder,
    minLength,
    maxLength,
    clear
  } = props

  const { name, ref, value, onChange } = controlledProps
    ? controlledProps
    : props

  const onBlur = props.onBlur || controlledProps?.onBlur

  return (
    <FormGroup>
      {label && (
        <Label for={name} className='g-searchbox-label'>
          {label.toUpperCase()}
        </Label>
      )}
      <div
        className={`g-searchbox ${disabled && 'disabled'}`}
        style={{ position: 'relative' }}
      >
        <input
          className={`${clear && 'clearable'}`}
          id={name}
          type='text'
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete={autocomplete}
          minLength={minLength}
          maxLength={maxLength}
          ref={ref}
        />
        <Icon name='search' color='primary' size={15} />
        {clear && (
          <div className='clear-icon' onClick={clear}>
            <Icon name='close' size='12px' />
          </div>
        )}
      </div>
      <FormText className='g-input-error' color='danger'>
        {errorText || controlledFieldState?.error?.message || ''}
      </FormText>
    </FormGroup>
  )
}

SearchBox.defaultProps = {
  disabled: false,
  placeholder: 'Ingrese texto de búsqueda'
}
